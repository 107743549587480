<template>
  <div class="admin-container" v-if="target">
    <v-row class="justify-space-between mb-10">
      <v-col cols="12" md="4">
        <h4>{{ target.full_name }}</h4>
        <h6>{{ target.role }}</h6>
      </v-col>
    </v-row>
    <div class="messages-wrapper">
      <div
        v-for="i in messages"
        :key="i.id"
        :class="{
          'message-sent-by-me': i.sent_by == 'ME',
          'message-sent-by-other': i.sent_by != 'ME',
        }"
      >
        <div class="message-detail-time">{{ i.created_at }}</div>
        <div v-if="i.timeline">
          <span class="d-block message-send-from mt-3"
            >Odesláno z etapy:
            <strong>
              <router-link
                class="col-secondary-1"
                :to="{ name: 'Timeline-detail', params: { id: i.timeline.id } }"
              >
                {{ i.timeline.name }}
              </router-link>
            </strong></span
          >
        </div>
        <div v-if="i.projecting">
          <span class="d-block message-send-from mt-3"
            >Odesláno z projektování:
            <strong>
              <router-link
                class="col-secondary-1"
                :to="{
                  name: 'Projecting-detail',
                  params: { id: i.projecting.id },
                }"
              >
                {{ i.projecting.name }}
                <span v-if="i.projecting_subdirectory"
                  ><strong>({{ i.projecting_subdirectory.name }})</strong></span
                >
              </router-link>
            </strong></span
          >
        </div>
        <div v-if="i.construction_diary">
          <span class="d-block message-send-from mt-3"
            >Odesláno z stavební deník:
            <strong>
              <router-link
                class="col-secondary-1"
                :to="{
                  name: 'ConstructionDiary-detail',
                  params: { id: i.construction_diary.id },
                }"
              >
                {{ i.construction_diary.name }}
              </router-link>
            </strong></span
          >
        </div>
        <div v-if="i.payment">
          <span class="d-block message-send-from mt-3"
            >Odesláno z platby:
            <strong>
              <router-link
                class="col-secondary-1"
                :to="{ name: 'Payments-detail', params: { id: i.payment.id } }"
              >
                {{ i.payment.name }}
              </router-link>
            </strong></span
          >
        </div>
        <div v-if="i.complaint">
          <span class="d-block message-send-from mt-3"
            >Odesláno z reklamace:
            <strong>
              <router-link
                class="col-secondary-1"
                :to="{
                  name: 'Complaints-detail',
                  params: { id: i.complaint.id },
                }"
              >
                {{ i.complaint.name }}
              </router-link>
            </strong></span
          >
        </div>
        <div v-if="i.section == 'MESSAGE'">
          <span class="d-block message-send-from mt-3"
            >Odesláno z <strong>Zprávy</strong></span
          >
        </div>
        <div v-if="i.section == 'CONTACT'">
          <span class="d-block message-send-from mt-3"
            >Odesláno z <strong>Kontakt</strong></span
          >
        </div>
        <div v-if="i.section == 'CONTRACT'">
          <span class="d-block message-send-from mt-3"
            >Odesláno z
            <strong>
              <router-link class="col-secondary-1" :to="{ name: 'Contracts' }"
                >Smlouvy</router-link
              >
            </strong></span
          >
        </div>
        <div class="sender-name">{{ i.sender.full_name }}</div>
        <div class="message-content-single">{{ i.content }}</div>
        <div class="message-content-single mt-3">
          <div
            class="cursor-pointer text-right"
            v-for="document in i.documents"
            :key="document.id"
            style="font-size: 12px"
            @click="downloadDoc(document.id)"
          >
            příloha:
            <strong style="text-decoration: underline">{{
              document.name
            }}</strong>
          </div>
        </div>
        <div
          class="message-content-single d-flex justify-end align-center mt-3"
          v-if="i.sent_by == 'ME' && i.is_read"
        >
          <div class="cursor-pointer text-right" style="font-size: 10px">
            <span class="d-inline-block mr-1 col-secondary-2">přečteno</span>
            <v-icon style="font-size: 14px" color="light-green"
              >mdi-check-all</v-icon
            >
          </div>
        </div>
        <div
          class="message-content-single d-flex justify-end align-center mt-3"
          v-if="i.sent_by == 'ME' && !i.is_read"
        >
          <div class="cursor-pointer text-right" style="font-size: 10px">
            <span class="d-inline-block mr-1 col-secondary-2">doručeno</span>
            <v-icon style="font-size: 14px" color="light-green"
              >mdi-check</v-icon
            >
          </div>
        </div>
      </div>
    </div>
    <div class="message-write-wrapper" v-if="user && user.role != 'SUPERUSER'">
      <div class="message-actions">
        <v-textarea
          label="Obsah zprávy"
          v-model="message"
          rows="1"
          auto-grow
          @keydown.enter.prevent="sendMessage"
        ></v-textarea>
        <v-btn
          class="message-send-btn"
          small
          tile
          color="secondary"
          @click="sendMessage"
          >Odeslat</v-btn
        >
      </div>
      <div class="messages-documents" v-if="documents.length">
        <div
          class="message-document"
          v-for="(document, documentIdx) in documents"
          :key="documentIdx"
        >
          <span
            >Příloha: <strong>{{ document.document_name }}</strong></span
          >
          <v-btn
            class="ml-2"
            color="error"
            @click="handleDeleteDocument(document.document_id, documentIdx)"
            x-small
            text
            max-height="15"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </div>
      <div class="d-flex justify-end messages-input-actions">
        <v-btn
          class="message-send-btn align-self-end"
          x-small
          text
          color="secondary"
          @click.stop="handleClick('documentFile')"
          ><v-icon>mdi-file</v-icon></v-btn
        >
        <v-btn
          class="message-send-btn align-self-end"
          x-small
          text
          color="secondary"
          @click.stop="handleClick('photoFile')"
          ><v-icon>mdi-camera</v-icon></v-btn
        >

        <input
          style="display: none"
          ref="documentFile"
          type="file"
          class="input--file"
          @input="handleInputFile($event.target.files[0])"
        />

        <input
          style="display: none"
          ref="photoFile"
          type="file"
          accept="image/*,video/*"
          capture="camera/camcorder"
          class="input--file"
          @input="handleInputFile($event.target.files[0])"
        />
      </div>
    </div>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import messageService from '../api/messageService';
import documentService from '../api/documentService';
import errorHandler from '../api/errorHandler';
export default {
  async created() {
    bus.$emit('processing', true);
    await this.show();
    bus.$emit('processing', false);
    setTimeout(() => {
      this.fixMessageAction();
      this.scrollToBottomOfMessages();
    }, 220);

    setTimeout(() => {
      this.fixMessageAction();
      // this.scrollToBottomOfMessages();
    }, 330);
    const INTERVAL_SECONDS = 60;
    // setInterval(
    //   async () => await this.intervalFetch(),
    //   1000 * INTERVAL_SECONDS
    // );
  },
  destroyed() {
    clearInterval(this.intervalFetch);
    window.removeEventListener('resize', this.fixMessageAction);
  },
  mounted() {
    // setTimeout(() => {
    //   this.fixMessageAction();
    //   this.scrollToBottomOfMessages();
    // }, 220);

    // setTimeout(() => {
    //   this.fixMessageAction();
    //   // this.scrollToBottomOfMessages();
    // }, 330);

    window.addEventListener('resize', this.fixMessageAction);
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  data() {
    return {
      data: [],
      target: null,
      documents: [],
      messages: [],
      message: null,
    };
  },
  methods: {
    async handleDeleteDocument(documentId, documentIdx) {
      try {
        bus.$emit('processing');
        await documentService._delete(documentId);
        this.documents.splice(documentIdx, 1);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    handleClick(ref, id) {
      this.focusedId = id;
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        bus.$emit('processing');
        const { data: document } = await documentService.store(f);
        console.debug(document);
        this.documents.push({
          document_id: document.id,
          document_name: document.client_name,
        });
        // await constructionDiaryService.storeDocuments(
        //   {
        //     documents: [
        //       {
        //         document_id: document.id,
        //         document_name: document.client_name,
        //         mime: document.mime,
        //       },
        //     ],
        //   },
        //   this.focusedId
        // );
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    scrollToBottomOfMessages() {
      if (document) {
        const scrollview = document.querySelectorAll('.messages-wrapper');
        if (scrollview && scrollview[0]) {
          window.scroll({
            behavior: 'smooth',
            top: scrollview[0].childNodes[scrollview[0].childNodes.length - 1]
              .offsetTop,
          });
        }
      }
    },
    fixMessageAction() {
      console.log('Message action bar');
      if (document) {
        this.$nextTick(() => {
          const refWrap = document.querySelectorAll('.messages-wrapper');
          if (refWrap && refWrap[0]) {
            const width = refWrap[0].getBoundingClientRect().width;
            const messageWriteWrapper = document.querySelectorAll(
              '.message-write-wrapper'
            );
            messageWriteWrapper[0].style.width = width + 'px';
          }
        });
      }
    },
    async intervalFetch() {
      try {
        console.log('Interval fetch');
        const data = await messageService.show(this.$route.params.id);
        console.debug(data);
        this.target = data.target;
        this.messages = data.messages;
        this.scrollToBottomOfMessages();
      } catch (error) {
        console.error(error);
        clearInterval(this.intervalFetch);
      }
    },
    async show() {
      try {
        const data = await messageService.show(this.$route.params.id);
        this.target = data.target;
        this.messages = data.messages;
      } catch (error) {
        // Handle error
        if (!authHandler(error)) this.$router.push({ name: 'Login' });
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
          this.$router.push({ name: 'Dashboard' });
        }
      }
    },
    async sendMessage() {
      try {
        const now = new Date();
        await messageService.store({
          target_user_id: this.target.id,
          content: this.message,
          timeline_id: this.$route.query.timeline || null,
          projecting_id: this.$route.query.projecting || null,
          projecting_subdirectory_id:
            this.$route.query.projecting_subdirectory || null,
          construction_diary_id: this.$route.query.construction_diary || null,
          payment_id: this.$route.query.payment || null,
          section: this.$route.query.section || null,
          complaint_id: this.$route.query.complaint || null,
          documents: this.documents,
        });
        this.messages.push({
          id: null,
          sent_by: 'ME',
          sender: {
            full_name:
              this.$store.state.user.first_name +
              ' ' +
              this.$store.state.user.last_name,
          },
          content: this.message,
          created_at:
            now.getDate().toLocaleString() +
            '.' +
            (Number(now.getMonth().toLocaleString()) + 1) +
            '.' +
            +now.getFullYear() +
            ' ' +
            now.getHours().toLocaleString() +
            ':' +
            now.getMinutes().toLocaleString(),
        });
        await this.show();

        this.message = null;
        this.documents = [];
        setTimeout(() => {
          this.scrollToBottomOfMessages();
        }, 200);
      } catch (error) {
        console.debug(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  watch: {},
};
</script>
