import req from "./request";

export default {
  index: async (
    page = 1,
    limit = 25,
    orderBy = "id",
    descending = "desc",
    search = null,
    filter = null,
    filterFrom = null,
    filterTo = null
  ) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `messages?building_id=${
        building.id
      }&page=${page}&limit=${limit}&orderBy=${orderBy}&descending=${
        descending ? "desc" : "asc"
      }`;
      if (search) url += `&search=${search}`;
      if (filter) url += `&filter=${filter}`;
      if (filterTo) url += `&filter_to=${filterTo}`;
      if (filterFrom) url += `&filter_from=${filterFrom}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async (id) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const { data } = await req.get(`messages/${id}?building_id=${building.id}`, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  store: async (data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const response = await req.post(`messages?building_id=${building.id}`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
